import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Table } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import axios from 'axios';
import { BASE_URL } from "./baseUrl";
import { SITE_URL } from "./siteUrl";
import { useParams } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';

const CoursesCalendarSec = styled.section`
  width: 100%;
  min-height: 500px;
 
  .trbg {
    background-color: #f0f0f0;
  }
 
  table {
    border:solid 1px #ccc;
    th {
        border:solid 1px #ddd;
        padding-left: 15px;
    }
    td {
        border:solid 1px #ccc;
        padding-left: 15px;
    }
  }

`

function Cer() {

    const [inb, setInb] = useState([]);
    useEffect(() => {
        axios.get(`${BASE_URL}/certificatebanner`).then(
            res => setInb(res.data)
        )
    }, []);


    const [data, setData] = useState({
        cer_number: "",
        pass_year: "",
        name: '',
        cer_status: '',
        c_name: '',
    })


    const { cer_number } = useParams();

    useEffect(() => {
        axios.get(`${BASE_URL}/certificates?q=${cer_number}`).then(
            res => setData(res.data)
        )
    }, [])

    let dataDiv;
    let tHight;
    let scolor;
    let downloadImg;
    let cerImg = { width: '120px', height: '120px', objectFit: 'cover' };
    let hide;

    if (data.cer_status === 'In Progress') {
        scolor = { color: '#dc3545', paddingTop: '5px' };
        downloadImg = '';

    }

    else if (data === '') {
        downloadImg = '';

    }

    else if(data.cer_image == '') {
        downloadImg = '';
    }

    else if (data.cer_status === 'Ready to Issue') {
        scolor = { color: '#3692e1' };
       
    }

    else {
        scolor = { color: '#198754', fontWeight: '500' };
        downloadImg = <a href={`${SITE_URL}/images/certificates/${data.cer_image}`} target="_blank"><img src={`${SITE_URL}/images/certificates/${data.cer_image}`} style={cerImg} alt={data.name} /></a>
    }

    if (data === '') {
        dataDiv = <div><p style={{ fontSize: 24, textAlign:'center', fontWeight: '500', color: '#999' }}>Sorry, No Records Found!</p></div>;
        tHight = {};


    } else {
        tHight = { height: 70, verticalAlign: 'middle' };
     dataDiv =  <Table responsive="lg">
     <thead>
         <tr className='trbg'>
             <th>Name</th>
             <th>Course</th>
             <th>Certificate Number</th>
             <th>Pass Year</th>
             <th>Status</th>
             <th>Certificate</th>

         </tr>
     </thead>
     <tbody>
         <tr>
             <td style={tHight}>{data.name}</td>
             <td style={tHight}>{data.c_name}</td>
             <td style={tHight}>{data.cer_number}</td>
             <td style={tHight}>{data.pass_year}</td>
             <td style={tHight}><p style={scolor}>{data.cer_status}</p></td>
             <td style={tHight}>{downloadImg}</td>
         </tr>

     </tbody>
 </Table>

    }

   

    const [meta, setMeta] = useState([]);
    useEffect(() => {
        axios.get(`${BASE_URL}/certificateseo`).then(
            res => setMeta(res.data)
        )
    }, [])

    return (

        <>
         <HelmetProvider>
         {meta.map(item=>
           <Helmet key={item.id}>
           <title>{item.page_title}</title>
           <meta name="keywords" content={item.keywords}/>
           <meta name="description" content={item.description}/>
           </Helmet>
          )}
          </HelmetProvider>

            {inb.map(item =>
                <div className="inner-banner" key={item.id}>
                    <img src={`${SITE_URL}/images/innerbanners/${item.image}`} alt={`${item.alt}`} />
                    <div className="inner-banner-cont">
                        <Container>
                            <Row>
                                <Col lg={12}>
                                    <h1>{item.title}</h1>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            )}


            <CoursesCalendarSec className="inner-sec">
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className="title">
                                <h1>Certificate Details</h1>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col lg={12}>
                            {dataDiv}
                        </Col>
                    </Row>


                </Container>

            </CoursesCalendarSec>




        </>



    )
}

export default Cer;