import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { Fancybox } from "@fancyapps/ui/src/Fancybox/Fancybox.js";
import "@fancyapps/ui/dist/fancybox.css";
import { device } from './Device';
import axios from 'axios';
import { BASE_URL } from './baseUrl';
import { SITE_URL } from "./siteUrl";
import { NavLink } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';



const GalleryCont = styled.div`
width: 100%;
padding-bottom: 100px;
`
const GalleryBox = styled.div`
width: 100%;
padding: 15px;
border-radius: 10px;
background-color: #fff;
margin-bottom: 20px;

.img-box {
  width: 100%;
  height: 230px;
  overflow: hidden;
  border-radius: 5px;
}
  img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  transition: all 0.4s;
}
h1 {
  font-size: 18px;
  color: #333;
  padding-top: 13px;
  transition: all 0.4s;
}


&:hover {
  img {
    transform: scale(1.2);
  }
  h1 {
    color: ${({ theme }) => theme.colors.primary};
  }
}
`

function Gallery() {

    const [inb, setInb] = useState([]);
    useEffect(() => {
        axios.get(`${BASE_URL}/gallerybanner`).then(
            res => setInb(res.data)
        )
    }, []);

    const [gallery, setGallery] = useState([]);
    useEffect(() => {
        axios.get(`${BASE_URL}/gallery`).then(
            res => setGallery(res.data)
        )
    }, []);

    const [meta, setMeta] = useState([]);
    useEffect(() => {
        axios.get(`${BASE_URL}/galleryseo`).then(
            res => setMeta(res.data)
        )
    }, [])


    return (

        <>
        <HelmetProvider>
            {meta.map(item =>
                <Helmet key={item.id}>
                    <title>{item.page_title}</title>
                    <meta name="keywords" content={item.keywords} />
                    <meta name="description" content={item.description} />
                </Helmet>
            )}
            </HelmetProvider>

            {inb.map(item =>
                <div className="inner-banner" key={item.id}>
                    <img src={`${SITE_URL}/images/innerbanners/${item.image}`} alt={`${item.alt}`} />
                    <div className="inner-banner-cont">
                        <Container>
                            <Row>
                                <Col lg={12}>
                                    <h1>{item.title}</h1>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            )}

            <GalleryCont className="inner-sec bg-light">
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className="title">
                                <h1>Our Gallery</h1>
                            </div>
                        </Col>
                    </Row>
                    <Row className='mt-5'>
                        {gallery.map(item =>
                            <Col lg={3} md={3}>
                                <NavLink to={`/gallery-images/${item.id}`}>
                                    <GalleryBox>
                                        <div className='img-box'>
                                            <img src={`${SITE_URL}/images/gallery/${item.cover}`} alt={`${item.name}` + " " + "M.S Institute of Engineering"} />
                                        </div>
                                        <h1>{item.name}</h1>
                                    </GalleryBox>
                                </NavLink>
                            </Col>
                        )}


                    </Row>
                </Container>
            </GalleryCont>

        </>
    )
}

export default Gallery;