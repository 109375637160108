import react, { useState, useEffect, useLocation } from 'react';
import { NavLink, useParams, useNavigate } from "react-router-dom";
import axios from "axios"
import styled from "styled-components";
import { device } from '../Device';
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL } from '../baseUrl';

const intialstate = {
    name: "",
    mobile: "",
    course: "",
    email: "",
};


const HomeRegFormContainer = styled.div`
  width: 100%;
  position: relative;
  z-index: 2;
  padding: 30px 0;
  
  .home-reg-form-inner {
    width: 100%;
    min-height: 200px;
    background: #fff;
    padding: 30px;
    margin-top: 15px;
    position: relative;
    box-shadow: 0 0 3px #d5d5d5;
    outline: 1px dashed ${({ theme }) => theme.colors.primary};
    outline-offset: -10px;

    .toast-pos {
        position: absolute;
        bottom: 0px;
        width: 45%;

        @media ${device.lg} {
            width: 98%;
            margin: auto;
        }
    }

    h1 {
        font-size: 24px;
    }
   label {
    margin-bottom: 5px;
   }
  .spin-hide {
    display: none
  }
  .spin-show {
    display: block;
  }
  }
`

function HomeRegForm() {
 
    const [state, setsate] = useState(intialstate);
    const { name, mobile, course, location } = state;

    const history = useNavigate();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setsate({ ...state, [name]: value })
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!name || !mobile || !course || !location) {
            toast.error("Please fill all the fields");
        }

        else if (course === 'Select Course') {
            toast.error("Please Select Course");
        }

        else {
            const res = await axios.post(`${BASE_URL}/insert`, {
                name,
                mobile,
                course,
                location
            });


            if (res.data.status_set === 300) {
                toast.success(res.data.message);
                setsate({ name: "", mobile: "", course: "", location: "" })
               
            }


            else {
                toast.error("Network Error, Please try after sometime.");
            }

        }
    };

    const [courses, setCourses] = useState([]);
    useEffect(() => {
        axios.get(`${BASE_URL}/courses`).then(
            res => setCourses(res.data)
        )
    }, []);



    return (
        <div className="inner-sec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 pc-title">
                        <div className="title text-center">
                            <h1>Register your Course</h1>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 mx-auto">
                        <HomeRegFormContainer id="Register-your-course">
                            <form onSubmit={handleSubmit}>
                                <div className="home-reg-form-inner">
                                    <ToastContainer position='bottom-center' className="toast-pos" limit={1} transition={Zoom} />
                                    <div className="row mt-2">
                                        <div className="col-lg-3 mt-3">
                                            <label>Name</label>
                                            <input type="text" name="name" value={name} onChange={handleInputChange} className="form-control" placeholder="Enter your Name" />
                                        </div>

                                        <div className="col-lg-3 mt-3">
                                            <label>Mobile</label>
                                            <input type="text" name="mobile" value={mobile} onChange={handleInputChange} className="form-control" placeholder="Enter your Mobile Number" />
                                        </div>
                                        <div className="col-lg-3 mt-3">
                                            <label>Course</label>
                                            <select className="form-select" name="course" value={course} onChange={handleInputChange} aria-label="Default select example">
                                                <option>Select Course</option>
                                                {courses.map(item =>
                                                    <option key={item.id} value={item.course}>{item.course}</option>
                                                )}


                                            </select>
                                        </div>
                                        <div className="col-lg-3 mt-3">
                                            <label>Location</label>
                                            <input type="text" name="location" value={location} onChange={handleInputChange} className="form-control" placeholder="Enter your Location" />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-6 mt-4"><button type="submit" className="main-btn">Register Now
                                        </button></div>

                                    </div>

                                </div>
                            </form>
                        </HomeRegFormContainer>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeRegForm;