import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { Fancybox } from "@fancyapps/ui/src/Fancybox/Fancybox.js";
import "@fancyapps/ui/dist/fancybox.css";
import { device } from '../Device';
import axios from 'axios';
import { BASE_URL } from '../baseUrl';
import { SITE_URL } from "../siteUrl";
import { NavLink } from 'react-router-dom';


const GalleryCont = styled.div`
width: 100%;

`
const GalleryBox = styled.div`
width: 100%;
padding: 15px;
border-radius: 10px;
background-color: #fff;

.img-box {
  width: 100%;
  height: 230px;
  overflow: hidden;
  border-radius: 5px;
}
  img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  transition: all 0.4s;
}
h1 {
  font-size: 18px;
  color: #333;
  padding-top: 13px;
  transition: all 0.4s;
}


&:hover {
  img {
    transform: scale(1.2);
  }
  h1 {
    color: ${({ theme }) => theme.colors.primary};
  }
}
`

function HomeGallery() {

  const [gallery, setGallery] = useState([]);
  useEffect(() => {
    axios.get(`${BASE_URL}/gallery`).then(
      res => setGallery(res.data)
    )
  }, []);

 
  return (

    <>

      <GalleryCont className="inner-sec bg-light">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="title">
                <h1>Our Gallery</h1>
              </div>
            </Col>
          </Row>
          <Row className='mt-5'>
            {gallery.slice(0,4).map(item=>
              <Col lg={3} md={3} key={item.id}>
              <NavLink to={`/gallery-images/${item.id}`}>
                <GalleryBox>
                  <div className='img-box'>
                    <img src={`${SITE_URL}/images/gallery/${item.cover}`} alt={`${item.name}`} />
                  </div>
                  <h1>{item.name}</h1>
                </GalleryBox>
              </NavLink>
            </Col>
              )}
            
            
          </Row>
        </Container>
      </GalleryCont>

    </>
  )
}

export default HomeGallery;