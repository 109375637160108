import React, { useEffect, useState } from 'react';
import { Container, Row, Col} from "react-bootstrap";
import styled from "styled-components";
import { device } from './Device';
import axios from 'axios';
import { BASE_URL } from "./baseUrl";
import { SITE_URL } from "./siteUrl";
import { Helmet, HelmetProvider } from 'react-helmet-async';


const PlacementsSec = styled.div`
width: 100%;
h2 {
    font-size: 20px;
    color: ${({theme}) => theme.colors.primary};
    font-weight: 400;
    margin-top:30px
}
img {
    width: 100%;
    height: 110px;
    object-fit: cover;
    padding: 5px;
    border:solid 1px #f0f0f0;
    @media ${device.lg} {
        height: 100%;
    }
}
`

function Placements() {

  const [inb, setInb] = useState([]);
  useEffect(() => {
      axios.get(`${BASE_URL}/placebanner`).then(
          res => setInb(res.data)
      )
  }, []);

  const [client, setclient] = useState([]);
  useEffect(() => {
      axios.get(`${BASE_URL}/clients`).then(
          res => setclient(res.data)
      )
  }, []);

  const [meta, setMeta] = useState([]);
    useEffect(() => {
        axios.get(`${BASE_URL}/placementseo`).then(
            res => setMeta(res.data)
        )
    }, [])

    return (
        <>
        <HelmetProvider>
         {meta.map(item=>
           <Helmet key={item.id}>
           <title>{item.page_title}</title>
           <meta name="keywords" content={item.keywords}/>
           <meta name="description" content={item.description}/>
           </Helmet>
          )}
          </HelmetProvider>
             {inb.map(item => 
             <div className="inner-banner" key={item.id}>
             <img src={`${SITE_URL}/images/innerbanners/${item.image}`} alt={`${item.alt}`} />
             <div className="inner-banner-cont">
                 <Container>
                     <Row>
                         <Col lg={12}>
                             <h1>{item.title}</h1>
                         </Col>
                     </Row>
                 </Container>
             </div>
         </div>
            )}

            <PlacementsSec className="inner-sec mb-5">
              <Container>
                <Row className="mb-4">
                  <Col lg={12}>
                    <div className="title">
                        <h1>Our Placements</h1>
                        <h2>MS trained Engineering working below organizations</h2>
                    </div>
                  </Col>
                </Row>
                <Row className="mt-3 mb-5">
                  {client.map(item=> 
                     <Col lg={2} md={6} sm={6} xs={12} className="mt-4" key={item.id}>
                     <img src={`${SITE_URL}/images/clients/${item.image}`} alt={`${item.alt}`}/>
                   </Col>
                    )}
                    
                </Row>
              </Container>
            </PlacementsSec>

        </>
    )
}

export default Placements;