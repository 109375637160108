import React, { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from 'axios';
import { BASE_URL } from '../baseUrl';
import { SITE_URL } from '../siteUrl';

const settings = {
    dots: true,
    infinite: true,
    speed: 1500,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true,
    initialSlide: 0,
    autoplaySpeed: 4000,
    //cssEase: "linear",
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3,
                infinite: true,
                dots: true
            }
        },
        {
            breakpoint: 991,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
            }
        },
        {
            breakpoint: 575,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};


const CourseSliderMain = styled.div`
 width: 100%;
 position: relative;

 .slick-prev:before, .slick-next:before {
    font-size: 30px;
    color: ${({ theme }) => theme.colors.primary};  
 }
 .slick-prev, .slick-next {
    top: -20px;
    width: 30px;
    height: 30px;
    display: none !important;
 }
 .slick-next {
    right: 22px;
    z-index: 10;
    
 }
 .slick-prev {
    right: 52px;
    left: auto;
    z-index: 10;
 }
 .slick-dots {
    bottom: -10px;
 }
 .slick-dots li button:before {
    font-size: 10px;
    opacity: 1;
    color: #666;
 }
 .slick-dots li {
    margin: 0;
 }
 .slick-dots li.slick-active button:before {
    color: ${({ theme }) => theme.colors.primary}; 
 }
`

const CourseSlidercont = styled.div`
 width: 100%;
 min-height: 200px;
 
 margin: 0 auto;
 box-sizing: border-box;
 padding-left: 15px;
 padding-right: 15px;

.course-slider-inner {
    width: 100%;
    display: block;
    background-color: #fff;
    border-radius:10px;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
    margin-bottom: 30px;
    margin-top: 10px;
   
    a {
        color: inherit;
    }

 img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 10px 10px 0 0;
 }

 h1 {
    width: 100%;
    min-height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    padding-left: 15px;
    padding-right: 15px;

  
    
 }

}

`


function HomeOurCourses() {
    
    const  [courses, setCourses] = useState([]);
    useEffect(() => {
        axios.get(`${BASE_URL}/courses`).then(
            res => setCourses(res.data)
        )
    }, []);
     

    return (
        <CourseSliderMain className="inner-sec bg-light">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="title">
                        <h1>Our Courses</h1>
                        </div>
                    </div>
                </div>

                <div className="row mt-5">
                    <Slider {...settings}>
                        {courses.map(item => <div key={item.id}>
                            <CourseSlidercont>
                            <div className="course-slider-inner">
                            <img
                            className="b-img"
                            src={`${SITE_URL}/images/courses/${item.c_image}`}
                            alt={`${item.alt}`}/>
                                      <NavLink to={`/courses/${item.page_link}`}><h1>{item.course}</h1></NavLink>
                                </div>
                            </CourseSlidercont>
                        </div>)}
                       
                    </Slider>
                </div>



            </div>



        </CourseSliderMain>
    );
}

export default HomeOurCourses;