import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Table } from "react-bootstrap";
import styled from "styled-components";
import axios from 'axios';
import { BASE_URL } from "./baseUrl";
import { SITE_URL } from "./siteUrl";
import { Helmet, HelmetProvider } from 'react-helmet-async';


const CoursesCalendarSec = styled.section`
  width: 100%;
  p {
    margin-bottom: 15px;
  }
  table td {
   white-space: nowrap;
  }
`


function CoursesCalendar() {

    const [inb, setInb] = useState([]);
    useEffect(() => {
        axios.get(`${BASE_URL}/ccbanner`).then(
            res => setInb(res.data)
        )
    }, []);

    const [cc, setcc] = useState([]);
    useEffect(() => {
        axios.get(`${BASE_URL}/coursecalendar`).then(
            res => setcc(res.data)
        )
    }, []);

    const [ccontent, setccontent] = useState([]);
    useEffect(() => {
        axios.get(`${BASE_URL}/coursecontent`).then(
            res => setccontent(res.data)
        )
    }, []);

    const [meta, setMeta] = useState([]);
    useEffect(() => {
        axios.get(`${BASE_URL}/coursescalendarseo`).then(
            res => setMeta(res.data)
        )
    }, [])

    return (
        <>
        <HelmetProvider>
         {meta.map(item=>
           <Helmet key={item.id}>
           <title>{item.page_title}</title>
           <meta name="keywords" content={item.keywords}/>
           <meta name="description" content={item.description}/>
           </Helmet>
          )}
          </HelmetProvider>
            {inb.map(item =>
                <div className="inner-banner" key={item.id}>
                    <img src={`${SITE_URL}/images/innerbanners/${item.image}`} alt={`${item.alt}`} />
                    <div className="inner-banner-cont">
                        <Container>
                            <Row>
                                <Col lg={12}>
                                    <h1>{item.title}</h1>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            )}

            <CoursesCalendarSec className="inner-sec">
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className="title">
                                <h1>Courses Calendar</h1>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-5">
                        <Col lg={12}>
                            <Table striped bordered responsive="lg">
                                <thead>
                                    <tr>
                                        <th>S.No.</th>
                                        <th>Course Title</th>
                                        <th>Batch Timings</th>
                                        <th>Faculty</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {cc.map((item, index) => {
                                        return (
                                            <tr key={item.id}>
                                                <td>{index + 1}</td>
                                                <td>{item.c_title}</td>
                                                <td>{item.b_timings}</td>
                                                <td>{item.faculty}</td>
                                            </tr>
                                        )

                                    })}
                                    
                                </tbody>
                            </Table>
                        </Col>
                    </Row>

                    <Row className="mt-4">
                        <Col lg={12}>
                            {ccontent.map(item=> {
                                const data = item.content;
                                return (
                                    <div key={item.id} dangerouslySetInnerHTML={{ __html: data }}></div>
                                )
                            } )}
                            
                        </Col>
                    </Row>
                </Container>

            </CoursesCalendarSec>
        </>
    )
}


export default CoursesCalendar;