import React, { useEffect, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import MswBanner from "../../images/Home-Banner.jpg";
import CivilBanner from "../../images/civil-banner.jpg";
import MechanicleBanner from "../../images/mechanical-banner.jpg";
import SaftyBanner from "../../images/safety-banner.jpg";
import { device } from '../Device';
import axios from 'axios';
import { BASE_URL } from '../baseUrl';
import { SITE_URL } from '../siteUrl';

const SliderBg = styled.div`
    width: 100%;
    height: 620px;
    position: relative;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .slider {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items:center;
        background: rgb(0,0,0);
        background: linear-gradient(270deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);
        z-index: 1;

        .banner-caption {
            color: rgb(255, 255, 255);
            width: 100%;
            margin: auto;
            min-height: 300px;
            display: flex;
            -webkit-box-pack: center;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;
            padding: 30px;
            border-radius: 4px;
            text-align: left;

            .banner-btn a {
                background-color: rgb(254, 106, 34);
                color: rgb(255, 255, 255);
                width: 170px;
                height: 50px;
                border-radius: 4px;
                font-size: 18px;
                font-weight: 400;
                transition: all 0.3s ease 0s;
                display: flex;
                -webkit-box-pack: justify;
                justify-content: space-between;
                -webkit-box-align: center;
                align-items: center;
                box-sizing: border-box;
                padding-left: 20px;
                position: relative;
                box-shadow: rgb(0 0 0 / 16%) 0px 10px 36px 0px, rgb(0 0 0 / 6%) 0px 0px 0px 1px;

                &:hover {
                    background-color: rgb(255, 255, 255);
                    color: rgb(51, 51, 51);
                }

                span {
                    width: 25%;
                    height: 100%;
                    background-color: rgb(255, 255, 255);
                    color: rgb(51, 51, 51);
                    position: absolute;
                    right: 0px;
                    display: flex;
                    -webkit-box-pack: center;
                    justify-content: center;
                    -webkit-box-align: center;
                    align-items: center;
                    border-radius: 0px 4px 4px 0px;
                }
             }

            h1 {
                font-weight: 600;
                font-size: 3.2rem;
                margin-bottom: 15px;
                @media (max-width:991px) {
                    font-size: 2.2rem;
                }
            }

            p {
                color: rgb(240, 240, 240);
                font-size: 20px;
                text-align: left;
            }

            ul {
                text-align: center;

                li {
                    display: inline-block;
                    margin-bottom: 20px;
                    margin-left: 5px;
                    margin-right: 5px;

                    a {
                        padding: 10px 20px;
                        color: rgb(51, 51, 51);
                        font-size: 18px;
                        border: 0px solid rgb(204, 204, 204);
                        border-radius: 5px;
                        background-color: rgba(255, 255, 255, 0.8);
                        display: block;
                        transition: all 0.3s ease-in 0s;
                        box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
                        font-weight: 400;

                        @media (max-width:991px) {
                            padding: 5px 8px;
                        } 

                        &:hover {
                            /* background-color: rgba(0, 0, 0, 0.7); */
                            background-color: ${({theme}) => theme.colors.primary};
                            box-shadow: rgb(0 0 0 / 35%) 0px 5px 15px;
                            color: rgb(255, 255, 255);
                        }
                    }
                }
            }
        }
    }

`


function ControlledCarousel() {
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    const [mainBannerImg, setMainBannerImg] = useState([]);
    const [mainBannerCourse, setMainBannerCourse] = useState([]);
    const [banner, setBanner] = useState([]);

    useEffect(() => {
        axios.get(`${BASE_URL}/mainbannerimage`).then(
            res => setMainBannerImg(res.data)
        )
    }, [])

    useEffect(() => {
        axios.get(`${BASE_URL}/mainbannercourse`).then(
            res => setMainBannerCourse(res.data)
        )
    }, [])

    useEffect(() => {
        axios.get(`${BASE_URL}/bannerimage`).then(
            res => setBanner(res.data)
        )
    }, [])

     //let viewmore = banner.map(item =><>{item.status}</>);
    // console.log(viewmore);
     //let viewbtn = viewmore;
//   if(viewmore === 'Active') {
//     viewbtn = <NavLink to="/">View More<span><i className="bi bi-arrow-right-circle"></i></span></NavLink>
//   }
//   else {
//     viewbtn = <NavLink to="/">No Link<span><i className="bi bi-arrow-right-circle"></i></span></NavLink>
//   }

    return (


        <Carousel activeIndex={index} onSelect={handleSelect}>
            <Carousel.Item>
                <SliderBg>
                    {mainBannerImg.map(item =>

                        <img
                            key={item.id} 
                            className="b-img"
                            src={`${SITE_URL}/images/banners/${item.banner_img}`}
                            alt={`${item.alt}`}></img>

                    )}

                    <Carousel.Caption className='slider'>

                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12">
                                        <div className="banner-caption">
                                        {mainBannerImg.map(item =><h1 key={item.id}>{item.title}</h1>)}
                                            <ul>
                                                {mainBannerCourse.map(item => <li key={item.id}>
                                                    <NavLink to={`/${item.page_link}`}>{item.course_title}</NavLink></li>)}
                                               
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        
                    </Carousel.Caption>
                </SliderBg>
            </Carousel.Item>

            {banner.map(item =>
                
                <Carousel.Item key={item.id}>
              <SliderBg>
                <img
                    
                    className="b-img"
                    src={`${SITE_URL}/images/banners/${item.banner_img}`}
                    alt={`${item.alt}`}
                />

                <Carousel.Caption className='slider'>
                   
                       
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-8">
                                        <div className="banner-caption">
                                            <h1>{item.title}</h1>
                                            <p>{item.description}</p>
                                            <div className="banner-btn  mt-3 mt-sm-5"><NavLink to="/job-oriented-courses-offered">View More<span><i className="bi bi-arrow-right-circle"></i></span></NavLink></div>
                                            {/* <div className='banner-btn  mt-3 mt-sm-5'>{viewbtn}</div> */}
                                        </div>
                                    </div>

                                </div>
                            </div>
                        
                    
                </Carousel.Caption>
                </SliderBg>
            </Carousel.Item>
                
                )}

        </Carousel>

    );
}

function SiteBanner() {

    return (

        <div style={{ marginTop: "112px" }}>
            <ControlledCarousel />
        </div>

    );
}

export default SiteBanner;