import styled from "styled-components";

const Infosec = styled.div`
 width: 100%;
 height: 100px;
 background-color: #333;
 color: #fff;
 border-radius: 4px;
 display: flex;
 justify-content: center;
 align-items: center;
 padding: 10px;
 
  i {
    font-size: 32px;
  }

  h1 {
    font-size: 20px;
    font-weight: 400;
  }

  h2 {
    font-size: 16px;
    font-size: 400;
    color: #f5f5f5;
    margin-top: 10px;
    font-weight:300;
    line-height: 22px;
  }


`



function HomeInfo() {
    return (
       <div className="inner-sec">
         <div className="container">
            <div className="row">
                <div className="col-lg-3 col-md-6 mb-3">
                    <Infosec>
                        <div className="col-3 text-center"><i className="bi bi-hand-thumbs-up"></i></div>
                        <div className="col-9">
                            <h1>100%</h1>
                            <h2>Job Oriented Courses</h2>
                        </div>
                    </Infosec>
                </div>
                <div className="col-lg-3 col-md-6 mb-3">
                    <Infosec>
                        <div className="col-3 text-center"><i className="bi bi-people"></i></div>
                        <div className="col-9">
                            <h1>Leaders</h1>
                            <h2>In Technology Training</h2>
                        </div>
                    </Infosec>
                </div>
                <div className="col-lg-3 col-md-6 mb-3">
                    <Infosec>
                        <div className="col-3 text-center"><i className="bi bi-globe-central-south-asia"></i></div>
                        <div className="col-9">
                            <h1>Trained</h1>
                            <h2>Engeneers Across the Global</h2>
                        </div>
                    </Infosec>
                </div>
                <div className="col-lg-3 col-md-6 mb-3">
                    <Infosec>
                        <div className="col-3 text-center"><i className="bi bi-building"></i></div>
                        <div className="col-9">
                            <h1>Bridging</h1>
                            <h2>The Gap Between Academia & Industry</h2>
                        </div>
                    </Infosec>
                </div>
            </div>
         </div>
       </div>
    )
}

export default HomeInfo;