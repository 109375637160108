import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
html {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  scroll-behavior: auto !important;
}
 body {
    /* font-family: 'Open Sans', sans-serif; */
    font-family: 'Rubik', sans-serif;
    background: #fff;
    color: #333;
    box-sizing: border-box;
    overflow-x: hidden;
    font-weight: 400;
 }
 a {
   text-decoration: none !important;
 }
 p {
   margin: 0;
   padding: 0;
 }
 ul,li {
    list-style: none;
    margin: 0;
    padding: 0;
}
h1,h2,h3,h4,h5,h6{
    margin: 0;
    padding: 0;
    /* font-family: 'Poppins', sans-serif; */
}
 header {
    width: 100%;
  
 }
 .loader-show {
  width: 100%;
  height: 100vh;
  position: fixed;
  background: #fff;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 999; 
  transition: all 0.8s;
  visibility: visible;
  opacity: 1;
 }
 .loader-hide {
   transition: all 250ms linear 2s;
   display: none;
 }
 .container {
   width: 100%;
   max-width: 1280px;
   margin: 0 auto;
 }
 .container-flex {
   display: flex;
   justify-content: space-between;
 }
 .bg-light {
  background: #f5f5f5 !important;
 }
 .bg-light-red {
  background: #FAF0EA;
   position: relative;
   z-index: -10;
 }
 .bg-light-z {
  background: #f5f5f5 !important;
  position: relative;
  z-index: -10;
 }
 .inner-sec {
  padding: 50px 0;
 }
 .f-img {
  width: 100%;
 }
 .top-to-scroll {
    visibility: visible !important;
    opacity: 1 !important;
    bottom: 30px !important;
   }
 .carousel-indicators {
  display: flex;
  flex-direction: row;
  /* left: auto; */
  /* top: 45%; */
  /* margin-right: 20px; */
  /* bottom: auto; */
  /* display: flex; */
  bottom: 50px;
 }
 .carousel-indicators button {
  width: 14px !important;
  height: 14px !important;
  background: transparent !important;
  border:solid 2px #fff !important;
  border-radius: 100%;
  opacity: 1 !important;
  transition: all 0.4s;
  /* margin-bottom: 30px; */
  margin-bottom: 5px;
  box-shadow: 0 0 3px #111;
 }
 .carousel-indicators button.active {
  /* background: ${({ theme }) => theme.colors.primary} !important; */
  position: relative;
  
 }
 .carousel-indicators button::after {
  position: absolute;
  content: '';
  left: 4px;
  right: 0px;
  bottom: 0px;
  top: 0px;
  width: 0px;
  height: 0px;
  background-color: ${({ theme }) => theme.colors.primary} !important;
  border-radius: 100%;
  margin: auto;
 }
 .carousel-indicators button.active::after {
  width: 100%;
  height: 100%;
  transition: all 0.9s;
  left: 0;
 }
 .carousel-control-prev, .carousel-control-next {
      display: none !important;
  }
 @media (max-width:768px) {
  .carousel-indicators {
    bottom: 34px;
  }
  .carousel-indicators button {
    width: 10px !important;
    height: 10px !important;
  }
}
@media (max-width:991px) {
    .carousel-control-prev, .carousel-control-next {
      display: none !important;
  }
 }
  
 .form-control, .form-select {
  background-color: #f5f5f5;
  height: 45px;
  border-radius: 3px;
 }
 .form-control:focus, .form-select:focus {
  box-shadow: none;
  border:solid 1px ${({ theme }) => theme.colors.primary}
 }
 button.main-btn {
  border:none;
  outline: none;
 }
 .main-btn {
    padding: 10px 25px;
    background: #fe6a22;
    color: #fff;
    border-radius: 3px;
    transition: all 0.3s;
    font-weight: 400;
    font-family: 'Poppins', sans-serif;
 }
 .main-btn:hover {
  background: #333;
 }
 .title h1 {
  font-size: 28px;
  position: relative;
  display: inline;
 }
 .title h1::after {
  content: '';
  position: absolute;
  bottom: -8px;
  width: 100%;
  height: 2px;
  background: ${({ theme }) => theme.colors.primary};
  left: 0;
  right: 0;
  opacity: 0.5;
  z-index: 5;
 }
 .title h1::before {
  content: '';
  position: absolute;
  bottom: -12px;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: ${({ theme }) => theme.colors.primary};
  left: 0;
  z-index: 10;
  
 }
 .pc-title .title h1::before {
  /* animation: linear infinite;
  animation-name: run;
  animation-duration: 15s;  */
  left: 50%;
 }
 @keyframes run {
  0% {
    left: 0;
  }
  50% {
    left: 100%;
  }
  100% {
    left: 0;    
  }
}

/*==== Marquee Css =====*/
.marquee { 
  width: 100%; 
  overflow: hidden; 
  white-space: nowrap;
  /* background-color: #ffb08d; */
  border-top:solid 0px #ffb08d;
  margin-top: -42px;
  z-index: 10;
  position: relative;
  /* background-color: ${({theme}) => theme.colors.rgb}; */
  background-color: rgba(0,0,0,0.6);
}
.marquee:hover .marquee__item { 
  -webkit-animation-play-state: paused; 
  animation-play-state: paused; 
}
.marquee__seperator { 
  margin: 0 2rem; 
}
.marquee__item { 
  text-align: center; 
  position: relative;
  display: inline-block; 
  will-change: transform;  
  animation: marquee 25s linear infinite; 
}
.marquee__item span { 
  font-size: 18px;
  /* text-transform: capitalize; */
  color: #fff;
  line-height: 42px;
}
.marquee__item span {
  position: relative; 
  padding-left: 40px;
   padding-right: 40px;
  }
.marquee__item span::after {
  position: absolute;
  content: '';
  right: 0;
  width: 1px;
  height: 15px;
  top: 4px;
  background: #fff;
}

 @-webkit-keyframes marquee {  0% {
 -webkit-transform: translateX(0);
 transform: translateX(0);
}
 100% {
 -webkit-transform: translateX(-100%);
 transform: translateX(-100%);
}
}
 @keyframes marquee {  0% {
 -webkit-transform: translateX(0);
 transform: translateX(0);
}
 100% {
 -webkit-transform: translateX(-100%);
 transform: translateX(-100%);
}
}
/*==== End Marquee Css =====*/
.header-height {
        height: 110px !important;
        transition: all 0.4s;
        border-bottom: solid 1px #f0f0f0;
        box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px;
    }

    .header-height .logo {
      max-width: 200px !important;
      padding-top: 6px !important; 
      transition: all 0.4s;
    }

   .track-cer-bg {
    background-color: #fff3ee;
   } 
   .fancybox__thumbs .carousel__slide .fancybox__thumb::after {
    border-color:${({theme}) => theme.colors.primary};
   }
   .nav-show {
          left: 0 !important;
     }


.inner-banner {
 width: 100%;
 height: 300px;
 position: relative;
 margin-top: 110px;
 color:#fff;

 @media (max-width:991px) {
  height: 150px;
 }

 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
 }

 .inner-banner-cont {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: linear-gradient(270deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5634628851540616) 100%);
    display: flex;
    justify-content: flex-start;
    align-items: center;

    h1 {
        font-size: 50px;
        text-transform: uppercase;
        font-weight: 600;
        @media (max-width:991px) {
          font-size: 30px;
        }
    }
    h2 {
        font-size: 18px;
        margin-top: 15px;
        color: ${({theme})=>theme.colors.primary};
    }
 }


}
.b-img {
  width: 100%;
  height: 620px;
  object-fit: cover;
}
`;

export default GlobalStyle
