import React, { useEffect, useState } from 'react';
import axios from 'axios';
import styled from "styled-components";
import { device } from '../Device';
import { BASE_URL } from '../baseUrl';
import { NavLink } from 'react-router-dom';

const FindCertificateForm = styled.div`
width:100%;
max-width: 90%;
min-height: 350px;
margin-left: auto;
position: relative;

  @media ${device.md} {
    margin-right: auto;
  }


        .cer-cont {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            right:0;
            top: 0;
            bottom: 0;
            z-index: 2;
            background: #fff;
            padding: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            box-shadow: 0 0 3px #d5d5d5;
        }

    &::before {
        position: absolute;
        content: '';
        top: -2px;
        left: -2px;
        width: 20%;
        height: 20%;
        background-color: ${({ theme }) => theme.colors.primary};
        
    
    }

    &::after {
        position: absolute;
        content: '';
        bottom: -2px;
        right: -2px;
        width: 20%;
        height: 20%;
        background-color: ${({ theme }) => theme.colors.primary};
    
    
    }

    h2 {
        font-size: 20px;
        text-align: center;
    }

    input[type=text] {
        width: 100%;
        margin-top: 30px;
        height: 45px;
        outline: none;
        border:solid 1px #ccc;
        padding-left: 15px;
        transition: all 0.4s;
        margin-bottom: 20px;

        &:focus {
            border: solid 1px #fe6a22;
            box-shadow: 0 0 5px rgb(254 106 34 / 40%);
        }
    }

`
const FindCertificateFormCont = styled.div`
 width: 100%;
 padding: 30px 10px;
 margin-top: 30px;

h3 {
    font-size: 16px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.primary};
}
p {
    font-size: 16px;
    line-height: 24px;
    margin-top: 20px;
    
}

`
const CerResult = styled.div`
 width: 100%;
 min-height: 200px;
 background: #fff;
 border-radius: 5px;
 box-shadow: 0 0 5px #d5d5d5;
 padding: 20px;
 margin-bottom: 10px;
 
.cer-color {
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 500;
}

.cer-download-btn {
    background-color: #5cdb80;

    &:hover {
        background-color: ${({ theme }) => theme.colors.primary};
        color: #fff;
    }
}

`

function HomeTrackCertificate() {

    const [inputValue, setInputValue] = useState('')
    const handleClick = () => {
        console.log(inputValue)
    }

    const inputUrl = encodeURIComponent(`${inputValue}`);

  



    return (
        <div>
            <div className="inner-sec bg-light">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12 pc-title text-center">
                            <div className="title">
                                <h1>Track Certificate</h1> 
                            </div>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-lg-4 col-md-5 mt-5">
                            <FindCertificateForm>
                                <div className="cer-cont">
                                    <h2>Track Your Certificate Here</h2>
                            
                                 
                                    <input type="text" placeholder="Enter your Certificate Number"
                                        value={inputValue} onChange={e => setInputValue(e.target.value)}
                                        required />

                                    
                                  
                                    { (inputValue !== "") && <NavLink to={`/certificates/${inputUrl}`}><button type="submit" onClick={handleClick} className="main-btn rounded-0 mt-4">Track Certificate</button></NavLink> }


                                 
                                  

                                </div>
                            </FindCertificateForm>
                        </div>
                        <div className="col-lg-7 col-md-6 mt-0 mt-md-5  offset-lg-1 offset-md-1">
                            <FindCertificateFormCont>
                                <h3>Track Your Certificate</h3>
                                <p>Certificate number will be provide after completing your course. Certificate will be issued within 15 to 30 days of your course complete. You can check your certificate status and download directly from our website by entering your certicate number.</p>
                            </FindCertificateFormCont>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default HomeTrackCertificate;