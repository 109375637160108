import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import axios from 'axios';
import { BASE_URL } from "./baseUrl";
import { SITE_URL } from "./siteUrl";
import { useParams } from 'react-router-dom';
import { device } from './Device';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ToastContainer, toast, Slide, Zoom, Flip, Bounce } from 'react-toastify';

const CourseDetailsCont = styled.section`
  width: 100%;
  background:#f5f5f5;
  p {
    margin-bottom: 15px;
  }
`

const CouseList = styled.div`
  width:100%;
  background:#fff;
  min-height: 200px;
  border-radius: 4px;

 h2 {
    width: 100%;
    padding: 10px 10px 10px 20px;
    background-color: ${({ theme }) => theme.colors.primary};
    font-size: 20px;
    color: #fff;

    small {
        font-weight: 400;
        font-size: 14px;
    }
 }

 ul {
    width: 100%;
    padding-bottom: 10px;

    li {
     width: 100%;
    }

    li:last-child a {
        border-bottom: none !important;
    }

    li a {
        color: #333;
        font-size: 16px;
        padding: 10px 10px 10px 25px;
        border-bottom: solid 1px #f0f0f0;
        display: block;
        transition: all 0.4s;
        position: relative;

        &:hover {
            padding-left: 35px;

            &::after {
                width: 100%;
            }
        }

        &::after {
            position: absolute;
            content: '';
            bottom:0;
            left: 0;
            right: 0;
            width: 0;
            height: 1px;
            background: #999;
            transition: all 0.4s;
            
        }
    }

    li:last-child a:hover {
        &::after {
          width: 0;
        }
    }
 }

`
const CourseDetailsList = styled.div`
width: 100%;
padding: 8px 15px 15px 20px;
background: #fff;
  h1 {
    font-size: 30px;
    margin-top: 10px;
    margin-bottom: 30px;
  }
  h4 {
    font-size: 18px;
    margin-bottom: 6px;
    text-transform:lowercase !important;
    &:first-letter {
            text-transform: uppercase !important;
        }
  }

 p {
    text-transform:lowercase !important;
        &:first-letter {
            text-transform: uppercase !important;
        }
 }

ul {
    width: 100%;
    padding: 5px;
    background: #fff;
    border-radius: 4px;
    position: relative;
    padding-left: 40px;
    margin-bottom: 20px;

    li {
        padding:5px 10px 0px 20px;
        position: relative;
        text-transform:lowercase !important;
        &:first-letter {
            text-transform: uppercase !important;
        }
     &::before {
        position: absolute;
        content: '';
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: ${({ theme }) => theme.colors.primary};
        left: 0;
        top: 15px;
     }
    }

}
`
//Form
const HomeRegFormContainer = styled.div`
  width: 100%;
  position: relative;
  z-index: 2;
  padding: 30px 0;
  
  .home-reg-form-inner {
    width: 100%;
    min-height: 200px;
    background: #fff;
    padding: 30px;
    margin-top: 15px;
    position: relative;
    box-shadow: 0 0 3px #d5d5d5;
    outline: 1px dashed ${({ theme }) => theme.colors.primary};
    outline-offset: -10px;

    .toast-pos {
        position: absolute;
        bottom: -90px;
        width: 98%;

        @media ${device.lg} {
            width: 98%;
            margin: auto;
        }
    }

    h1 {
        font-size: 24px;
    }
   label {
    margin-bottom: 5px;
   }
  .spin-hide {
    display: none
  }
  .spin-show {
    display: block;
  }
  }
`

function Courses() {

    const [inb, setInb] = useState([]);
    useEffect(() => {
        axios.get(`${BASE_URL}/cfbanner`).then(
            res => setInb(res.data)
        )
    }, []);

    const [mechanicalCourses, setMechanicalCourses] = useState([]);
    useEffect(() => {
        axios.get(`${BASE_URL}/mechanicalcourses`).then(
            res => setMechanicalCourses(res.data)
        )
    }, [])


    const [mechanicalCount, setMechanicalCount] = useState(0);
    useEffect(() => {
        fetch(`${BASE_URL}/mechanicalcourses`)
            .then(response => response.json())
            .then(data => {
                setMechanicalCount(data.length);
            });
    }, []);


    //Course Details
    const [data, setData] = useState({
        c_cat: "",
        course: "",
        c_des: "",
        page_title:"",
        keywords:"",
        description: "",
    })

   
    const { page_link } = useParams();

    useEffect(() => {
        axios.get(`${BASE_URL}/courses/${page_link}`).then(
            res => setData(res.data)
        )
    }, [page_link])

   
    // Form

const intialstate = {
    name: "",
    mobile: "",
    email: "",
    course: "",
};

    const [state, setsate] = useState(intialstate);
    const { name, mobile, course, location } = state;

    let cs = data.course;


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setsate({ ...state, [name]: value })
    }

    const handleSubmit = async (e) => {
       
        e.preventDefault();
        if (!name || !mobile || !location) {
            toast.error("Please fill all the fields");
        }


        else {
            const course = data.course;
            const res = await axios.post(`${BASE_URL}/insert`, {
                name,
                mobile,
                course,
                location
            });


            if (res.data.status_set === 300) {
                toast.success(res.data.message);
                setsate({ name: "", mobile: "", location: "" })
               
            }


            else {
                toast.error("Network Error, Please try after sometime.");
            }

        }
    };


    return (
        <>
        <HelmetProvider>
         <Helmet>
           <title>{data.page_title}</title>
           <meta name="keywords" content={data.keywords}/>
           <meta name="description" content={data.description}/>
         </Helmet>
         </HelmetProvider>
            {inb.map(item =>
                <div className="inner-banner" key={item.id}>
                    <img src={`${SITE_URL}/images/innerbanners/${item.image}`} alt={`${item.alt}`} />
                    <div className="inner-banner-cont">
                        <Container>
                            <Row>
                                <Col lg={12}>
                                    <h1>{item.title}</h1>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            )}

            <CourseDetailsCont className="inner-sec">
                <Container>
                    <Row>
                        <Col lg={4} className="mb-5 order-1 order-lg-0">
                            <CouseList>
                                <h2>{data.c_cat} <small>( {mechanicalCount} Course's )</small></h2>
                                <ul>
                                    {mechanicalCourses.map(item=>
                                        <li key={item.id}><NavLink to={`/mechanical-courses/${item.page_link}`}>{item.course}</NavLink></li>
                                        )}
                                </ul>
                            </CouseList>

                            <HomeRegFormContainer id="Register-your-course">
                               
                               <form onSubmit={handleSubmit}>
                            
                                   <div className="home-reg-form-inner">
                                       <h4>Register for Course</h4>
                                       <ToastContainer position='bottom-center' className="toast-pos" limit={1} transition={Zoom} />
                                       <div className="row mt-2">
                                           <div className="col-lg-12 mt-3">
                                               <label>Name</label>
                                               <input type="text" name="name" value={name} onChange={handleInputChange} className="form-control" placeholder="Enter your Name" />
                                           </div>
   
                                           <div className="col-lg-12 mt-3">
                                               <label>Mobile</label>
                                               <input type="text" name="mobile" value={mobile} onChange={handleInputChange} className="form-control" placeholder="Enter your Mobile Number" />
                                           </div>
                                          
                                           <div className="col-lg-12 mt-3">
                                               <label>Course</label>
                                               <input type="text" name="course" value={cs} onChange={handleInputChange} className="form-control" disabled  />
                                           </div>
                                           <div className="col-lg-12 mt-3">
                                               <label>Location</label>
                                               <input type="text" name="location" value={location} onChange={handleInputChange} className="form-control" placeholder="Enter your Location" />
                                           </div>
                                       </div>
                                       <div className="row">
                                           <div className="col-lg-12 mt-4"><button type="submit" className="main-btn">Register Now
                                           </button></div>
   
                                       </div>
   
                                   </div>
                               </form>
                           </HomeRegFormContainer>

                        </Col>
                        <Col lg={8} className="mb-5">

                            <Row>
                                <Col lg={12}>
                                    <CourseDetailsList>

                                        <div className="">
                                            <h1>{data.course}</h1>
                                        </div>

                                        <div dangerouslySetInnerHTML={{ __html: data.c_des }}></div>
                                    </CourseDetailsList>
                                </Col>
                            </Row>

                        </Col>
                    </Row>
                </Container>
            </CourseDetailsCont>


        </>
    )
}

export default Courses;