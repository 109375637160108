import React, { useEffect, useState } from 'react';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
// import '../node_modules/bootstrap/dist/js/bootstrap.min.js';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';
import Header from './components/Header';
import GlobalStyle from './styles/GlobalStyle';
import HomePage from './components/HomePage';
import Footer from './components/Footer';
import About from './components/About';
import ScrollToTop from './components/ScrollToTop';
import CoursesOffered from './components/CoursesOffered';
import CoursesCalendar from './components/CoursesCalendar';
import Placements from './components/Placements';
import Gallery from './components/Gallery';
import Blog from './components/Blog';
import Contact from './components/Contact';
import MechanicalCourses from './components/MechanicalCourses';
import SafetyCourses from './components/SafetyCourses';
import CivilCourses from './components/CivilCourses';
import Cer from './components/Cer.js';
import Courses from './components/Courses';
import BlogDetails from './components/BlogDetails';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { BASE_URL } from './components/baseUrl';
import axios from 'axios';
import GalleryImages from './components/GalleryImages';



function App() {

  const theme = {
    colors: {
      primary: "#fe6a22",
      secondary: "#333",
      rgb: "rgba(254,106,34,0.7)",
    }
  }

  const [meta, setMeta] = useState([]);
    useEffect(() => {
        axios.get(`${BASE_URL}/homeseo`).then(
            res => setMeta(res.data)
        )
    }, [])

  return (
    
    <ThemeProvider theme={theme}>
 
      <BrowserRouter>
        <GlobalStyle />
        <ScrollToTop />
        <Header />
        <HelmetProvider>
          {meta.map(item=>
           <Helmet key={item.id}>
           <title>{item.page_title}</title>
           <meta name="keywords" content={item.keywords}/>
           <meta name="description" content={item.description}/>
           </Helmet>
          )}
          </HelmetProvider>
        
        <Routes>
          <Route path='/' element={<HomePage />} exact />
          <Route path='/about-us' element={<About />} exact />
          <Route path='/job-oriented-courses-offered' element={<CoursesOffered/>} exact />
          <Route path='/courses-calendar' element={<CoursesCalendar/>} exact />
          <Route path="/placements" element={<Placements/>} exact />
          <Route path="/events-gallery" element={<Gallery/>} exact />
          <Route path="/blog" element={<Blog/>} exact />
          <Route path="/contact" element={<Contact/>} exact />
          <Route path="/mechanical-courses/:page_link" element={<MechanicalCourses/>} exact />
          <Route path='/civil-courses/:page_link' element={<CivilCourses/>} exact />
          <Route path='/civil-courses' element={<CivilCourses/>} exact />
          <Route path='/safety-courses/:page_link' element={<SafetyCourses/>} exact />
          <Route path='/courses/:page_link' element={<Courses/>} exact />
          <Route path='/certificates/:cer_number' element={<Cer/>} exact/>
          <Route path='/blog/:page_link' element={<BlogDetails/>} exact />
          <Route path='/gallery-images/:id' element={<GalleryImages/>} exact />
        </Routes>
        <Footer />
      </BrowserRouter>
    </ThemeProvider>
 
  );
};

export default App;
