import react, { useState, useEffect, CSSProperties } from 'react';
import styled from "styled-components";
import logo from "../images/logo.jpg"
import { NavLink } from "react-router-dom";
import { device } from './Device';
import { HashLink } from 'react-router-hash-link';
import BeatLoader from "react-spinners/BeatLoader";
import axios from 'axios';
import { BASE_URL } from './baseUrl';
import { SITE_URL } from './siteUrl';


const HeaderMain = styled.section`
 position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 100;
    transition: all 0.4s;
    width: 100%;
    background: #fff;
     height: 120px;
    border-bottom: solid 1px #f0f0f0;
    display: flex;
    align-items: center;
    flex-direction: column;
    .logo {
        width: 100%;
        max-width: 250px;
        transition: all 0.4s;
        padding-top: 5px;

       @media ${device.sm} {
        max-width: 200px;
        padding-top: 10px;
       }

    }
  `

const HeaderTop = styled.div`
 width: 100%;
 background: rgb(254,106,34);
 background: linear-gradient(90deg, rgba(254,106,34,1) 40%, rgba(252,176,69,1) 100%);
 display: flex;
 align-items: center;
 padding-left: 30px;
 padding-right: 30px;

 @media ${device.md} { 
   padding-left: 0px !important;
   padding-right: 0px !important; 
  }

 ul {
    @media ${device.sm} {
        justify-content: space-between !important;
        display: flex;

        a {
            font-size: 14px;
        }
    }

   @media ${device.xs} {
      a {
        font-size: 12px;
      }
   }

 }

  ul li {
    display: inline;
    margin-right: 10px;

    a {
        color: #fff;
        display: inline-block;
        padding: 7px;
    }
  }

 .h-social {
   
    @media ${device.sm} {
        display: none;
    }
    
    li {
        padding: 10px 2px;
        margin: 0;

        @media ${device.md} { 
            padding: 0px !important;
   
         }
        
       a {
        display: inline-block;
        padding: 5px;
        color: #fff;
        transition: all 0.4s;
        font-size: 20px;
        &:hover {
            color: #333;
        }
       } 

       &:last-child  {
        max-width: 30px;
        border-right:none;
       }
    }
   
 }

`
const HeaderBottom = styled.div`
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;

  @media ${device.xl} { 
    .menu-icon {
        display: none;
    }
            
            .menu-icon {
                display: block;
                position: fixed;
                right: 30px;
                top: 72px;

                span {
                    display: block;
                    content: '';
                    background-color: #333;
                    height: 2px;
                    width: 25px;
                    transition: all ease-in-out 0.3s;

                    &::before {
                        display: block;
                        content: '';
                        background-color: #333;
                        height: 2px;
                        width: 25px;
                        transition: all ease-in-out 0.3s;
                        transform: translateY(-8px);
                    }
                    &::after {
                        display: block;
                        content: '';
                        background-color: #333;
                        height: 2px;
                        width: 25px;
                        transition: all ease-in-out 0.3s;
                        transform: translateY(6px);
                    }
                }

                    span.open-navbar-toggler::before {
                            transform: translateY(0) rotate(45deg);
                            }

                    span.open-navbar-toggler::after {
                        transform: translateY(-2px) rotate(-45deg);
                    }

                    span.open-navbar-toggler {
                        background-color: transparent;
                    }
            }
         }

         @media ${device.md} {
            padding-left: 0;
            padding-right: 0;
         }
`

const Nav = styled.ul`
    width: 100%;
    text-align: right;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    
    a {
        color: #333;
        transition: all 0.3s;
        font-size: 18px;
        
        &:hover {
            color: ${({ theme }) => theme.colors.primary};
        }
    }
    /* li:nth-last-child(-n+3) a{
     font-weight: 600;
     
    } */
    .right-links {
        padding:8px 15px;
        background: #333;
        color: #fff;
        border-radius: 4px;
        transition: all 0.3s;
        cursor: pointer;

        &:hover {
            background: ${({ theme }) => theme.colors.primary};
            color: #fff;
        }
        
     }
     @media ${device.xl} { 
        display: block;
        position: fixed;
        background: rgba(255,255,255,1);
        left: -150%;
        width: 100%;
        height: 100%;
        text-align: left;
        transition: all ease-in 0.5s;
        top: 100px;

        li {
            display: block;
            width: 100%;
            border-bottom: solid 1px #f0f0f0;
            padding-left: 30px;
            a {
                padding-top: 15px;
                padding-bottom: 15px;
                display: block;
            }
        }
        
        
       
     }

    
     
  `

const ScrollToTopSec = styled.div`
   position: fixed;
   right: 10px;
   bottom:10px;
   z-index: 10;
   transition: all 0.7s;
   visibility: hidden;
   opacity: 0;
   a {
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    height: 35px;
    border-radius: 5px;
    background-color: #fe6a22;   
   }
  `



function Header() {

    const [header, headerheight] = useState(false);
    const changevalueonscroll = () => {

        const scrollvalue = document.documentElement.scrollTop;
        if (scrollvalue >= 200) {
            headerheight(true);
        }
        else {
            headerheight(false);
        }
    };

    window.addEventListener('scroll', changevalueonscroll);

    const [topscroll, setscrolltop] = useState(false);
    const scrolltotopvalue = () => {
        const scrolltotop = document.documentElement.scrollTop;
        if (scrolltotop >= 300) {
            setscrolltop(true);
        }
        else {
            setscrolltop(false);
        }
    };

    window.addEventListener('scroll', scrolltotopvalue);


    const [isActive, setActive] = useState(false);
    const toggleClass = () => {
        setActive(!isActive);
    };


    const scrollWidthOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -160;
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
    }

    const [loading, setloading] = useState(false);
    useEffect(() => {
        setloading(true)
        setTimeout(() => {
            setloading(false)
        }, 1200)
    }, [])

    const [contact, setContact] = useState([]);
    useEffect(() => {
        axios.get(`${BASE_URL}/contact`).then(
            res => setContact(res.data)
        )
    }, [])

    

    const [social, setSocial] = useState([]);
    useEffect(() => {
        axios.get(`${BASE_URL}/social`).then(
            res => setSocial(res.data)
        )
    }, [])


    return (
        <header>

            {/* <div className={loading ? "loader-show" : "loader-hide"}>
                <img src={logo} alt="" className='mb-5' />
            <BeatLoader color="#fe6a22" />
            </div> */}

            <HeaderMain className={header ? "header-height" : ""}>
                <HeaderTop>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-lg-6 col-md-8 col-sm-8  text-sm-start text-center'>
                                <ul>
                                    <li>
                                        {contact.map(item =>
                                            <a key={item.id} href={`tel:${item.mobile}`}><i className="bi bi-phone"></i> {item.mobile}</a>
                                        )}

                                    </li>
                                    <li>
                                        {contact.map(item =>
                                            <a key={item.id} href={`mailto:${item.email}`}><i className="bi bi-envelope"></i> {item.email}</a>
                                        )}

                                    </li>
                                </ul>
                            </div>
                            <div className='col-lg-6 col-md-4 col-sm-4 text-sm-end text-center'>
                                <ul className='h-social'>
                                   
                                    {social.map((item) => {
                                        if (item.social === '<i className="bi bi-facebook"></i>')
                                            return <li key={item.id}><a href={`${item.url}`} target="_blank" rel="noreferrer">
                                                <i className="bi bi-facebook"></i>
                                            </a></li>
                                        if (item.social === '<i className="bi bi-twitter"></i>')
                                            return <li key={item.id}><a href={`${item.url}`} target="_blank" rel="noreferrer">
                                                <i className="bi bi-twitter"></i>
                                            </a></li>
                                        if (item.social === '<i className="bi bi-linkedin"></i>')
                                            return <li key={item.id}><a href={`${item.url}`} target="_blank" rel="noreferrer">
                                                <i className="bi bi-linkedin"></i>
                                            </a></li>
                                        if (item.social === '<i className="bi bi-instagram"></i>')
                                            return <li key={item.id}><a href={`${item.url}`} target="_blank" rel="noreferrer">
                                                <i className="bi bi-instagram"></i>
                                            </a></li>
                                        if (item.social === '<i className="bi bi-whatsapp"></i>')
                                            return <li key={item.id}><a href={`${item.url}`} target="_blank" rel="noreferrer">
                                                <i className="bi bi-whatsapp"></i>
                                            </a></li>
                                        if (item.social === '<i className="bi bi-pinterest"></i>')
                                            return <li key={item.id}><a href={`${item.url}`} target="_blank" rel="noreferrer">
                                                <i className="bi bi-pinterest"></i>
                                            </a></li>
                                        if (item.social === '<i className="bi bi-pinterest"></i>')
                                            return <li key={item.id}><a href={`${item.url}`} target="_blank" rel="noreferrer">
                                                <i className="bi bi-pinterest"></i>
                                            </a></li>
                                            if (item.social === '<i className="bi bi-youtube"></i>')
                                            return <li key={item.id}><a href={`${item.url}`} target="_blank" rel="noreferrer">
                                                <i className="bi bi-youtube"></i> 
                                            </a></li>
                                    }
                                       
                                    )}
                                    
                                </ul>
                            </div>
                        </div>
                    </div>
                </HeaderTop>
                <HeaderBottom>
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-3">
                            {contact.map(item=>
                                <NavLink to="/" key={item.id}><img src={`${SITE_URL}/images/${item.logo}`} className="logo" 
                                alt={item.alt_logo} /></NavLink>
                                )}
                            </div>
                            <div className="col-lg-9">
                                <div className='menu-icon'><span className={isActive ? 'open-navbar-toggler' : null}
                                    onClick={toggleClass} ></span></div>
                                <Nav className={isActive ? 'nav-show' : null} onClick={toggleClass}>
                                    <li><NavLink to="/">Home</NavLink></li>
                                    <li><NavLink to="/about-us">About Us</NavLink></li>
                                    <li><NavLink to="/job-oriented-courses-offered">Courses Offered</NavLink></li>
                                    <li><NavLink to="/courses-calendar">Courses Calendar</NavLink></li>
                                    <li><NavLink to="/placements">Placements</NavLink></li>
                                    <li><NavLink to="/events-gallery">Gallery</NavLink></li>
                                    <li><NavLink to="/blog">Blog</NavLink></li>
                                    <li><NavLink to="/contact">Contact Us</NavLink></li>

                                    <div className='mt-3 mt-xl-0 ps-4 ps-xl-0'>
                                        <HashLink smooth to="/#Register-your-course" scroll={el => scrollWidthOffset(el)}>
                                            <span className='right-links'>Enroll Your Course</span></HashLink>
                                    </div>
                                </Nav>
                            </div>
                                    
                        </div>
                    </div>
                </HeaderBottom>

            </HeaderMain>
            <ScrollToTopSec className={topscroll ? "top-to-scroll" : ""}><HashLink smooth to="#top"> <i className="bi bi-arrow-up"></i> </HashLink></ScrollToTopSec>

        </header>
    );
}

export default Header;