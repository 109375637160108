import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { Fancybox } from "@fancyapps/ui/src/Fancybox/Fancybox.js";
import "@fancyapps/ui/dist/fancybox.css";
import axios from 'axios';
import { BASE_URL } from './baseUrl';
import { SITE_URL } from "./siteUrl";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useParams } from 'react-router-dom';


const GalleryImagesCont = styled.div`
width: 100%;
padding-bottom: 50px;
`
const GalleryBox = styled.div`
width: 100%;
height: 250px;
overflow: hidden;
border-radius: 5px;
margin-bottom: 30px;
border:solid 1px #ccc;

img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    transition: all 0.4s;
    object-fit: cover;

    &:hover {
        transform: scale(1.2);
    }
}

`

function GalleryImages() {

    const [inb, setInb] = useState([]);
    useEffect(() => {
        axios.get(`${BASE_URL}/gallerybanner`).then(
            res => setInb(res.data)
        )
    }, []);


    const [data, setData] = useState([])
    const { id } = useParams();

    useEffect(() => {
        axios.get(`${BASE_URL}/galleryimages/${id}`).then(
            res => setData(res.data)
        )
    }, [id])

    const [gname, setGname] = useState([]);
    useEffect(() => {
        axios.get(`${BASE_URL}/galleryname/${id}`).then(
            res => setGname(res.data)
        )
    }, [id])

    const [meta, setMeta] = useState([]);
    useEffect(() => {
        axios.get(`${BASE_URL}/galleryseo`).then(
            res => setMeta(res.data)
        )
    }, [])

    return (
        <>
        <HelmetProvider>
            {meta.map(item =>
                <Helmet key={item.id}>
                    <title>{item.page_title}</title>
                    <meta name="keywords" content={item.keywords} />
                    <meta name="description" content={item.description} />
                </Helmet>
            )}
            </HelmetProvider>
            {inb.map(item =>
                <div className="inner-banner" key={item.id}>
                    <img src={`${SITE_URL}/images/innerbanners/${item.image}`} alt={`${item.alt}`} />
                    <div className="inner-banner-cont">
                        <Container>
                            <Row>
                                <Col lg={12}>
                                    <h1>{item.title}</h1>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>
            )}

            <GalleryImagesCont className="inner-sec">
                <Container>
                    <Row>
                        <Col lg={12}>
                            <div className="title">
                                {gname.map(item=> 
                                    <h1 key={item.id}>{item.name}</h1>
                                    )}
                                
                            </div>
                        </Col>
                    </Row>
                    <Row className='mt-5'>
                        {data.map(item=>
                            <Col lg={3} md={3} key={item.id}>
                            <GalleryBox>
                               
                                <a data-fancybox="gallery-1" href={`${SITE_URL}/images/gallery/images/${item.image}`}>
                                    <img src={`${SITE_URL}/images/gallery/images/${item.image}`} alt={`${item.id}` + " " + "M.S Institute of Engineering"} />
                                </a>
                            </GalleryBox>
                        </Col>
                        )}
                        
                    </Row>
                </Container>
            </GalleryImagesCont>

        </>
    )
}

export default GalleryImages;