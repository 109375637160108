const size = {
    xs: '339px',
    sm: '590px',
    md: '768px',
    lg: '992px',
    xl: '1399px'
  }

  export const device = {
    xl: `(max-width: ${size.xl})`,
    lg: `(max-width: ${size.lg})`,
    md: `(max-width: ${size.md})`,
    sm: `(max-width: ${size.sm})`,
    xs: `(max-width: ${size.xs})`,
    
  };