import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import CivilBanner from "../../images/civil-banner.jpg";
import MechanicleBanner from "../../images/mechanical-banner.jpg";
import SaftyBanner from "../../images/safety-banner.jpg";
import CivilIcon from "../../images/civil-icon.png";
import MechanicalIcon from "../../images/mechanic-icon.png";
import SafetyIcon from "../../images/safety-icon.png";
import { NavLink } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import { device } from '../Device';
import axios from 'axios';
import { BASE_URL } from '../baseUrl';
import { SITE_URL } from '../siteUrl';

const PopularCat = styled.section`

  width: 100%;

h2 {
    color: ${({ theme }) => theme.colors.primary};
    font-size: 20px;
    text-align: center;
    margin-top: 25px;
}
.pct-cont {
    width: 100%;
    height: 390px;
    position: relative;
    border-radius: 5px;
    transition: all 0.4s ease;
    margin-bottom: 25px;


    &:hover {
        transform: translateY(-10px);
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background: rgb(0,0,0);
        background: linear-gradient(180deg, rgba(0,0,0,0) 49%, rgba(0,0,0,1) 100%);
        border-radius: 5px;
        transition: all 500ms ease;
        z-index: 1;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 5px;
    }

    .pct-img-text {
        position: absolute;
        width: 100%;
        min-height: 50px;
        width: 100%;
        left: 0;
        bottom: 0px;
        padding: 20px 20px;
        z-index: 5;
        text-align: left;

        img {
            width: 50px;
            object-fit: contain;
        }

        h1 {
            color: #fff;
            font-size: 22px;
        }
        h2 {
            text-align: left;
            margin-top: 10px;
            color: #fff;
            font-size: 16px;
            font-weight: 400;
           
        }
    }
}

a {
    color: #fff;
    transition: all 0.3s ease;
    &:hover {
        color: ${({ theme }) => theme.colors.primary};
    }
}
`

function HomePopularcat() {

    const scrollWidthOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -120;
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
    }

    //const [civil, setCivil] = useState([]);
    const [civilCount, setCivilCount] = useState(0);
    const [mechanicalCount, setMechanicalCount] = useState(0);
    const [safetyCount, setSafetyCount] = useState(0);

    useEffect(() => {
        fetch(`${BASE_URL}/civilcourses`)
            .then(response => response.json())
            .then(data => {
                setCivilCount(data.length);
            });
    }, []);

    useEffect(() => {
        fetch(`${BASE_URL}/mechanicalcourses`)
            .then(response => response.json())
            .then(data => {
                setMechanicalCount(data.length);
            });
    }, []);

    useEffect(() => {
        fetch(`${BASE_URL}/safetycourses`)
            .then(response => response.json())
            .then(data => {
                setSafetyCount(data.length);
            });
    }, []);


    const [civil, setCivil] = useState([]);
    useEffect(() => {
        axios.get(`${BASE_URL}/civilbanner`).then(
            res => setCivil(res.data)
        )
    }, [])

    const [mec, setMec] = useState([]);
    useEffect(() => {
        axios.get(`${BASE_URL}/mechanicalbanner`).then(
            res => setMec(res.data)
        )
    }, [])

    const [safety, setSafety] = useState([]);
    useEffect(() => {
        axios.get(`${BASE_URL}/safetybanner`).then(
            res => setSafety(res.data)
        )
    }, [])

   


    return (
        <PopularCat>
            <Container className="inner-sec">
                <Row>
                    <Col lg={12} className="pc-title">
                        <div className="title text-center">
                            <h1>Popular Courses Categories</h1>
                            <h2>Top Categories</h2>
                        </div>
                    </Col>
                </Row>

                <Row className="mt-5 px-3">

                    <Col lg={4} md={6}>
                        <div className="pct-cont">
                            {civil.slice(0,1).map(item=>
                                <img key={item.id} src={`${SITE_URL}/images/courses/${item.c_image}`} alt={`${item.alt}`} />
                                )}
                            
                            <div className="pct-img-text">
                                <Row>
                                    <Col xs={3} className="text-end">
                                        <img src={CivilIcon} alt="" className="icon" />
                                    </Col>
                                    <Col xs={9}>
                                        <h1><NavLink  to="/job-oriented-courses-offered">Civil Courses</NavLink></h1>
                                        <h2>{civilCount} Courses</h2>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                    <Col lg={4} md={6}>
                        <div className="pct-cont">
                        {mec.slice(0,1).map(item=>
                                <img key={item.id} src={`${SITE_URL}/images/courses/${item.c_image}`} alt={`${item.alt}`} />
                                )}
                            <div className="pct-img-text">
                                <Row>
                                    <Col xs={3} className="text-end">
                                        <img src={MechanicalIcon} alt="" className="icon" />
                                    </Col>
                                    <Col xs={9}>
                                        <h1><NavLink to="/job-oriented-courses-offered">Mechanical Courses</NavLink></h1>
                                        <h2>{mechanicalCount} Courses</h2>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                    <Col lg={4} md={6}>
                        <div className="pct-cont">
                        {safety.slice(0,1).map(item=>
                                <img key={item.id} src={`${SITE_URL}/images/courses/${item.c_image}`} alt={`${item.alt}`} />
                                )}
                            <div className="pct-img-text">
                                <Row>
                                    <Col xs={3} className="text-end">
                                        <img src={SafetyIcon} alt="" className="icon" />
                                    </Col>
                                    <Col xs={9}>
                                        <h1><NavLink to="/job-oriented-courses-offered">Safety Courses</NavLink></h1>
                                        <h2>{safetyCount} Courses</h2>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>


            </Container>
        </PopularCat>

    )
}

export default HomePopularcat;