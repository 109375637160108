import React, { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import axios from 'axios';
import { BASE_URL } from './baseUrl';
import { SITE_URL } from './siteUrl';

const FooterSec = styled.footer`
 width: 100%;
 min-height: 200px;
 padding: 40px 0;
 background: #151515;
 color: #fff;
 .footer-logo {
    width: 100%;
    max-width: 220px;
    margin-bottom:20px;
    filter: brightness(9);
 }
 p {
    font-size: 14px;
    color: #fff;
    padding-right: 20px;
    line-height: 26px;
 }

 h1 {
    font-size: 26px;
    font-weight: 500;
    margin-top: 15px;
    
 }

 .fc-links {
        margin-top: 15px;


     li {
        margin-bottom: 10px;
        padding-left: 20px;
        position: relative;
        &::before {
         position: absolute;
         content: '';
         width: 6px;
         height: 6px;
         background: ${({ theme }) => theme.colors.primary};
         left: 0;
         top: 9px;
        }
        a {
            color: #fff;
            transition: all 0.3s ease;
            font-size: 14px;
            &:hover {
                color: ${({ theme }) => theme.colors.primary};
            }
        }
     }

    }

    .fa-links {
        margin-top: 15px;

        li {
            margin-bottom: 15px;
            position: relative;
            padding-left: 30px;
            font-size: 14px;

            i {
                position: absolute;
                left: 0;
                top: 0px;
                font-size: 20px;
                color: ${({ theme }) => theme.colors.primary};
            }
        }
    }

    .f-social {
        margin-top: 20px;
        
        li {
            margin-bottom: 10px;
            display: inline;
            margin-right: 15px;
            
            a {
                font-size: 20px;
                color: #fff;
                transition: all 0.3s;
                &:hover {
                    color: ${({ theme }) => theme.colors.primary};
                }
            }

            &:last-child a:hover {
            color: #1ec781;
            }
        }
    }
 
  
`
const BottomFooter = styled.div`
 width: 100%;
 padding: 30px 0;
 background-color: #151515;
 border-top: solid 1px #222;
 text-align: center;

p {
    font-size:16px;
    color: #ccc;
    a {
        color: #fff;
        transition: all 0.3s;
        &:hover {
            color: ${({ theme }) => theme.colors.primary};
            
        }
    }
}

`


function Footer() {

    const regex = /(<([^>]+)>)/ig;
    const [contact, setContact] = useState([]);
    useEffect(() => {
        axios.get(`${BASE_URL}/contact`).then(
            res => setContact(res.data)
        )
    }, []);

    const [social, setSocial] = useState([]);
    useEffect(() => {
        axios.get(`${BASE_URL}/social`).then(
            res => setSocial(res.data)
        )
    }, [])

    const [civilCourses, setCivilCourses] = useState([]);
    const [mechanicalCourses, setMechanicalCourses] = useState([]);
    const [safetyCourses, setSafetyCourses] = useState([]);
    useEffect(() => {
        axios.get(`${BASE_URL}/civilcourses`).then(
            res => setCivilCourses(res.data)
        )
    }, [])
    useEffect(() => {
        axios.get(`${BASE_URL}/mechanicalcourses`).then(
            res => setMechanicalCourses(res.data)
        )
    }, [])
    useEffect(() => {
        axios.get(`${BASE_URL}/safetycourses`).then(
            res => setSafetyCourses(res.data)
        )
    }, [])

    return (
        <>
            <FooterSec>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4">
                            {contact.map(item =>
                                <div key={item.id}>
                                    <img src={`${SITE_URL}/images/${item.flogo}`} alt={item.alt_flogo} className="footer-logo" />
                                    <p>{item.footer.replace(regex, '')}</p>
                                </div>
                            )}

                        </div>
                        <div className="col-lg-4 mt-4 mt-lg-0">
                            <h1>Our Courses</h1>
                            <ul className="fc-links">
                                {civilCourses.slice(0, 2).map(item =>
                                    <li key={item.id}><NavLink to={`/civil-courses/${item.page_link}`}>{item.course}</NavLink></li>
                                )}

                                {mechanicalCourses.slice(0, 2).map(item =>
                                    <li key={item.id}><NavLink to={`/mechanical-courses/${item.page_link}`}>{item.course}</NavLink></li>
                                )}

                                {safetyCourses.slice(0, 2).map(item =>
                                    <li key={item.id}><NavLink to={`/safety-courses/${item.page_link}`}>{item.course}</NavLink></li>
                                )}


                            </ul>
                        </div>
                        <div className="col-lg-4 mt-4 mt-lg-0">
                            <h1>Contact</h1>
                            {contact.map(item =>
                                <div key={item.id}>
                                    <ul className="fa-links">
                                        <li><i className="bi bi-geo-alt"></i>{item.address}</li>
                                        <li><i className="bi bi-telephone"></i> {item.mobile},<br />{item.amobile}</li>
                                        <li><i className="bi bi-envelope"></i> {item.email}</li>
                                    </ul>
                                </div>
                            )}
                            <ul className="f-social">


                                {social.map((item) => {
                                    if (item.social === '<i className="bi bi-facebook"></i>')
                                        return <li key={item.id}><a href={`${item.url}`} target="_blank" rel="noreferrer">
                                            <i className="bi bi-facebook"></i>
                                        </a></li>
                                    if (item.social === '<i className="bi bi-twitter"></i>')
                                        return <li key={item.id}><a href={`${item.url}`} target="_blank" rel="noreferrer">
                                            <i className="bi bi-twitter"></i>
                                        </a></li>
                                    if (item.social === '<i className="bi bi-linkedin"></i>')
                                        return <li key={item.id}><a href={`${item.url}`} target="_blank" rel="noreferrer">
                                            <i className="bi bi-linkedin"></i>
                                        </a></li>
                                    if (item.social === '<i className="bi bi-instagram"></i>')
                                        return <li key={item.id}><a href={`${item.url}`} target="_blank" rel="noreferrer">
                                            <i className="bi bi-instagram"></i>
                                        </a></li>
                                    if (item.social === '<i className="bi bi-whatsapp"></i>')
                                        return <li key={item.id}><a href={`${item.url}`} target="_blank" rel="noreferrer">
                                            <i className="bi bi-whatsapp"></i>
                                        </a></li>
                                    if (item.social === '<i className="bi bi-pinterest"></i>')
                                        return <li key={item.id}><a href={`${item.url}`} target="_blank" rel="noreferrer">
                                            <i className="bi bi-pinterest"></i>
                                        </a></li>
                                    if (item.social === '<i className="bi bi-pinterest"></i>')
                                        return <li key={item.id}><a href={`${item.url}`} target="_blank" rel="noreferrer">
                                            <i className="bi bi-pinterest"></i>
                                        </a></li>
                                    if (item.social === '<i className="bi bi-youtube"></i>')
                                        return <li key={item.id}><a href={`${item.url}`} target="_blank" rel="noreferrer">
                                            <i className="bi bi-youtube"></i>
                                        </a></li>
                                }

                                )}


                            </ul>
                        </div>
                    </div>
                </div>
            </FooterSec>

            <BottomFooter>
                {contact.map(item => {
                    const data = item.copy;
                    return (
                        <p key={item.id} dangerouslySetInnerHTML={{ __html: data }} />
                    )
                }
                )}

            </BottomFooter>

        </>

    )
}

export default Footer;