import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import axios from 'axios';
import { BASE_URL } from '../baseUrl';

const AcBox = styled.div`
width: 100%;
border-radius: 10px;
padding: 15px;
height: 150px;
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
border:dotted 0px #ccc;
 h1 {
    font-size: 40px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.primary};
    margin-bottom: 10px;
    transition: all 0.4s;
 }

  p {
    font-size: 20px;
    color: #666;
    font-weight: 400;
  }

`



function HomeAchieve() {

    const [counterOn, setCounterOn] = useState(false);

    const [ach, setAch] = useState([]);
    useEffect(() => {
        axios.get(`${BASE_URL}/achievement`).then(
            res => setAch(res.data)
        )
    }, []);

    return (
        <div className="inner-sec">
            <div className="container">
                <div className="row">
                    <div className="title">
                        <h1>Our Achievement</h1>
                    </div>
                </div>
                <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(true)}>
                    <div className="row mt-5">
                        {ach.map(item =>
                            <div className="col-lg-3 col-md-6 mt-3" key={item.id}>
                                <AcBox>
                                    <h1>{counterOn && <CountUp start={0} end={item.count} duration={4} delay={0} />}{item.symbol}</h1>
                                    <p>{item.name}</p>
                                </AcBox>
                            </div>
                        )}

                    </div>
                </ScrollTrigger>
            </div>
        </div>
    )
}

export default HomeAchieve;