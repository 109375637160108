import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import axios from 'axios';
import { BASE_URL } from "./baseUrl";
import { SITE_URL } from "./siteUrl";
import { Helmet, HelmetProvider } from 'react-helmet-async';

const AboutCont = styled.div`
 width: 100%;
 
 h2 {
    font-size: 20px;
    color: ${({ theme }) => theme.colors.primary};
    margin-bottom: 10px;
 }
 
 h1 {
    margin-bottom: 30px;
    font-size: 28px;
 }

 p {
    color: #666666;
    line-height: 26px;
    margin-top: 15px;
    margin-bottom: 15px;
 }

 img {
    /* border-radius: 5px; */
 }

.about-img {
    position: relative;

    &::before {
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        background: ${({ theme }) => theme.colors.primary};
        left: -5px;
        bottom: -5px;
        z-index: -1;
        /* border-radius: 5px; */
        opacity: 0.5;
    }

    img {
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    }
}

ul {
    padding-left: 10px;

    li {
        position: relative;
        padding-left: 15px;
        margin-bottom: 10px;

        &::before {
            position: absolute;
            content: '';
            width: 6px;
            height: 6px;
            background: ${({ theme }) => theme.colors.primary};
            left: 0;
            top: 8px;
        }
    }
}

.president-img {
    width: 100%;
    text-align: left;
    position: relative;
    img {
        width: 100%;
        max-width: 65%;
        margin: 0 auto;
        /* padding: 5px;
        border:solid 1px ${({ theme }) => theme.colors.primary}; */
    }

    .president-info {
    text-align: left;
    margin-top: 20px;
    position: absolute;
    width: 100%;
    max-width: 80%;
    min-height: 50px;
    padding: 10px;
    background: rgba(255,255,255,0.8);
    backdrop-filter: blur(5px);
    border-left: solid 2px ${({ theme }) => theme.colors.primary};
    bottom: 20px;
    left: 5%;
    right: 10px;
    border-right:solid 1px #f8f8f8;
    h4 {
        font-size: 16px;
        color: ${({ theme }) => theme.colors.primary};
    }

    h5 {
        font-size: 14px;
        color: #666;
        margin-top: 5px;
    }
}
}

`



function About() {

    const [inb, setInb] = useState([]);
    useEffect(() => {
        axios.get(`${BASE_URL}/abtbanner`).then(
            res => setInb(res.data)
        )
    }, []);

    const [about, setAbout] = useState([]);
    useEffect(() => {
        axios.get(`${BASE_URL}/about`).then(
            res => setAbout(res.data)
        )
    }, []);

    const [mng, setMng] = useState([]);
    useEffect(() => {
        axios.get(`${BASE_URL}/mng`).then(
            res => setMng(res.data)
        )
    }, []);

    const [meta, setMeta] = useState([]);
    useEffect(() => {
        axios.get(`${BASE_URL}/aboutseo`).then(
            res => setMeta(res.data)
        )
    }, [])

    return (
        <>
        <HelmetProvider>
         {meta.map(item=>
           <Helmet key={item.id}>
           <title>{item.page_title}</title>
           <meta name="keywords" content={item.keywords}/>
           <meta name="description" content={item.description}/>
           </Helmet>
          )}
          </HelmetProvider>
           {inb.map(item => 
             <div className="inner-banner" key={item.id}>
             <img src={`${SITE_URL}/images/innerbanners/${item.image}`} alt={`${item.alt}`} />
             <div className="inner-banner-cont">
                 <Container>
                     <Row>
                         <Col lg={12}>
                             <h1>{item.title}</h1>
                         </Col>
                     </Row>
                 </Container>
             </div>
         </div>
            )}
            
            {about.map(item => {
                const aboutOne = item.about_one;
                const aboutTwo = item.about_two;
                return (
                    <div key={item.id}>
                        <AboutCont className="inner-sec">
                            <Container>
                                <Row>
                                    <Col lg={7} md={6} className="mb-3">
                                        <div dangerouslySetInnerHTML={{ __html: aboutOne }}></div>

                                    </Col>
                                    <Col lg={{ span: 4, offset: 1 }} md={6}>
                                        <div className="about-img">
                                            <img src={`${SITE_URL}/images/about/${item.image_one}`} alt={`${item.alt_one}`} className="f-img" />
                                        </div>
                                    </Col>
                                </Row>


                            </Container>
                        </AboutCont>

                        <AboutCont className="inner-sec bg-light-z">
                            <Container>
                                <Row>
                                    <Col lg={4} md={6} className="mb-3 order-1 order-lg-0">
                                        <div className="about-img">
                                            <img src={`${SITE_URL}/images/about/${item.image_two}`} alt={`${item.alt_two}`} className="f-img" />
                                        </div>
                                    </Col>
                                    <Col lg={{ span: 7, offset: 1 }} md={6}>
                                        <div dangerouslySetInnerHTML={{ __html: aboutTwo }}></div>

                                    </Col>
                                </Row>
                            </Container>
                        </AboutCont>
                    </div>
                )
            }

            )}

            {mng.map(item => {
                const mng_content = item.m_content;
                return (
                    <AboutCont className="inner-sec" key={item.id}>
                        <Container>
                            <Row>
                                <Col lg={12} className="pc-title">
                                    <div className="title text-center">
                                        <h1>Management</h1>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Col lg={8} md={6} className="mt-3 md-3 pe-0 pe-md-5">
                                <div dangerouslySetInnerHTML={{ __html: mng_content }}></div>
                                   
                                </Col>
                                <Col lg={4} md={6} className="mt-3 ps-4">
                                    <div className="president-img">
                                        <img src={`${SITE_URL}/images/about/${item.image}`} alt={`${item.alt}`} />

                                        <div className="president-info">
                                            <h4>{item.name}</h4>
                                            <h5>{item.designation}</h5>
                                        </div>

                                    </div>

                                </Col>
                            </Row>
                        </Container>
                    </AboutCont>

                )
            }

            )}
        </>
    );
}

export default About;