import React, { useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import cbg from "../../images/courses-bg.jpg";
import axios from 'axios';
import { BASE_URL } from '../baseUrl';

const PopularCorsesSec = styled.section`
  width: 100%;
  /* background: #f2f2f2; */
  
  /* background: url(${cbg}); */
`

const PopularCorsesList = styled.ul`
 width: 100%;
 li {
    margin-bottom: 12px;
    box-shadow: 0 0 2px #d5d5d5;
 }
 li a{
   color:#333; 
   padding: 10px 20px;
   background-color: #fff;
   display: block;
   transition: all 0.2s;
   border-radius: 4px;
   border-left: 2px solid #333;
   position: relative;

   &:hover {
    background: linear-gradient(90deg, rgba(254,106,34,1) 30%, rgba(252,176,69,1) 100%);
    color: #fff;
    
   }


 }



 h2 {
    font-size: 20px;
    font-weight: 400;
    color: ${({theme}) => theme.colors.primary};
    margin-top: 10px;
    margin-bottom: 20px;
 }
`

function HomePopularCourses() {

    const [civilCourses, setCivilCourses] = useState([]);
    const [mechanicalCourses, setMechanicalCourses] = useState([]);
    const [safetyCourses, setSafetyCourses] = useState([]);
    useEffect(() => {
        axios.get(`${BASE_URL}/civilcourses`).then(
            res => setCivilCourses(res.data)
        )
    }, [])
    useEffect(() => {
        axios.get(`${BASE_URL}/mechanicalcourses`).then(
            res => setMechanicalCourses(res.data)
        )
    }, [])
    useEffect(() => {
        axios.get(`${BASE_URL}/safetycourses`).then(
            res => setSafetyCourses(res.data)
        )
    }, [])

    return (
        <PopularCorsesSec className="inner-sec bg-light" id="courses">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="title">
                            <h1>Popular Courses</h1>
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-lg-12">
                        <PopularCorsesList>
                            <div className="row">
                               <div className="col-lg-4 mb-4">
                                  <h2>Civil Courses</h2>
                                  {civilCourses.map(item => <li key={item.id}><NavLink to={`/civil-courses/${item.page_link}`}>{item.course}</NavLink></li>)}
                                  
                               </div>
                               <div className="col-lg-4 mb-4">
                                  <h2>Mechanical Courses</h2>
                                  {mechanicalCourses.map(item => <li key={item.id}><NavLink to={`/mechanical-courses/${item.page_link}`}>{item.course}</NavLink></li>)}
                                  
                               </div>
                               <div className="col-lg-4 mb-4">
                                  <h2>Safety Courses</h2>
                                  {safetyCourses.map(item => <li key={item.id}><NavLink to={`/safety-courses/${item.page_link}`}>{item.course}</NavLink></li>)}
                                                                   
                               </div>
                            </div>

                        </PopularCorsesList>
                    </div>
                </div>
            </div>

        </PopularCorsesSec>
    )
}


export default HomePopularCourses;