import React, { useEffect, useState } from 'react';
import styled from "styled-components";
import axios from 'axios';
import { BASE_URL } from '../baseUrl';


const HomeStripSec = styled.div`
width: 100%;

`

function HomeStrip() {

    const [text, setText] = useState([]);
    useEffect(() => {
        axios.get(`${BASE_URL}/text`).then(
            res => setText(res.data)
        )
    }, [])

    return (

        <HomeStripSec>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-12 p-0">
                       
                        <div className="marquee">
                            <div className="marquee__item">
                                {text.map(item=><span key={item.id}>{item.slide}</span>)}
                                {text.map(item=><span key={item.id}>{item.slide}</span>)}
                              
                           </div>
                            <div className="marquee__item">
                            {text.map(item=><span key={item.id}>{item.slide}</span>)}
                            {text.map(item=><span key={item.id}>{item.slide}</span>)}
                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </HomeStripSec>

    );
}

export default HomeStrip;