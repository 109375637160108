import SiteBanner from './Home/Banner';
import HomeStrip from './Home/HomeStrip';
import HomePopularcat from './Home/HomePopularCat';
import HomePopularCourses from './Home/HomePopularCourses';
import HomeRegForm from './Home/HomeRegForm';
import HomeOurCourses from './Home/HomeOurCourses';
import HomeInfo from './Home/HomeInfo';
import HomeTrackCertificate from './Home/HomeTrackCertificate';
import HomeAchieve from './Home/HomeAchievements';
import HomeGallery from './Home/HomeGallery';
import HomeBlog from './Home/HomeBlog';


function HomePage() {
    return(
        <>
        <SiteBanner/>
        <HomeStrip/>
        <HomePopularcat/>
        <HomePopularCourses/>
        <HomeRegForm/>
        <HomeOurCourses/>
        <HomeInfo/>
        <HomeTrackCertificate/>
        <HomeAchieve/>
        <HomeGallery/>
        <HomeBlog/>
        </>
    )
}

export default HomePage;
