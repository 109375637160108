import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import axios from 'axios';
import { BASE_URL } from "./baseUrl";
import { SITE_URL } from "./siteUrl";
import { Helmet, HelmetProvider } from 'react-helmet-async';

const ContactSec = styled.div`
width: 100%;
`

const ContactInner = styled.div`
 width: 100%;
 h2 {
    font-size: 18px;
    color:${({theme})=>theme.colors.primary};
    font-weight: 400;
    margin-bottom: 20px;
 }
 iframe {
    margin-top: 10px;
 }
 ul {
    margin-top: 20px;
    
    li {
        margin-bottom: 20px;
        position: relative;
        padding-left: 35px;

        i {
            position: absolute;
            left: 0;
            top: 2px;
            font-size: 16px;
            color: #000;
        }

        a {
            color: #333;
        }
    }
 }
`

function Contact() {

    const regex = /(<([^>]+)>)/ig;
    const [contact, setContact] = useState([]);
    useEffect(() => {
        axios.get(`${BASE_URL}/contact`).then(
            res => setContact(res.data)
        )
    }, []);

    const [inb, setInb] = useState([]);
    useEffect(() => {
        axios.get(`${BASE_URL}/contactbanner`).then(
            res => setInb(res.data)
        )
    }, []);

    const [meta, setMeta] = useState([]);
    useEffect(() => {
        axios.get(`${BASE_URL}/contactseo`).then(
            res => setMeta(res.data)
        )
    }, [])

    return(
        <>
        <HelmetProvider>
        {meta.map(item=>
           <Helmet key={item.id}>
           <title>{item.page_title}</title>
           <meta name="keywords" content={item.keywords}/>
           <meta name="description" content={item.description}/>
           </Helmet>
          )}
          </HelmetProvider>

        {inb.map(item => 
             <div className="inner-banner" key={item.id}>
             <img src={`${SITE_URL}/images/innerbanners/${item.image}`} alt={`${item.alt}`} />
             <div className="inner-banner-cont">
                 <Container>
                     <Row>
                         <Col lg={12}>
                             <h1>{item.title}</h1>
                         </Col>
                     </Row>
                 </Container>
             </div>
         </div>
            )}

            <Container className="inner-sec">
                <Row>
                    <Col lg={12} className="pc-title">
                        <div className="title text-center">
                            <h1>Contact Us</h1>
                        </div>
                    </Col>
                </Row>
            </Container>
            <ContactSec className="mb-5">
                <Container>
                    <Row>
                        <Col lg={6} className="mb-5">
                         <ContactInner>
                            <h2>Our Location</h2>
                            {contact.map(item=>{
                                const data = item.map;
                                return (
                                    <div key={item.id} dangerouslySetInnerHTML={{ __html: data }}></div>
                                )
                            })}
                        
                         </ContactInner>
                        </Col>

                       <Col lg={6}>
                       <ContactInner className="ps-0 ps-sm-5">
                        <h2>Contact Details</h2>
                        {contact.map(item=>{
                            return (
                                <ul key={item.id}>
                                  <li><i className="bi bi-geo-alt"></i> {item.address}</li>
                                  <li><i className="bi bi-phone"></i>{item.mobile}, {item.amobile}</li>
                                  {/* <li><i className="bi bi-telephone"></i>{item.land}</li> */}
                                  <li><i className="bi bi-envelope"></i> <a href={`mailto:${item.email}`}>{item.email}</a></li>
                                  <li><i class="bi bi-globe-central-south-asia"></i> <a href="https://mswhyd.com/" target='_blank'>www.mswhyd.com</a></li>
                                </ul>
                            )
                        })}
                        
                        </ContactInner>
                       </Col>

                    </Row>
                </Container>
            </ContactSec>
        </>
    )
}

export default Contact;